<template>
	<div id="container" :class="{ contain: true, mobile: isMobile }">
		<div class="contant">
			<div class="title-45">$UPTICK Airdrop Supplementary Campaign  Featured Creator NFT Holders</div>
			<div class="des mt-10 mb-7">In order to thank the users of the Uptick NFT Marketplace on IRISnet, a special
				campaign has been launched as a countdown for the Uptick Mainnet. If you hold the designated NFTs
				released by either Uptick Official or our Featured Creators, you will be eligible to claim the
				corresponding $UPTICK rewards after the Uptick Mainnet goes live. </div>
			<div class="title-13">The campaign is divided into three stages:</div>
			<div class="title-13-bold">Collection Stage</div>
			<div class="title-13-normal">This stage has ended, and the snapshot was taken on the 24th April at 7:00 (UTC+0)</div>
			<div class="title-13-bold">Claiming Stage</div>
			<div class="title-13-normal ">Claiming starts on the 26th of April at 7:00 (UTC+0) for a total of 10 weeks.</div>
			<div class="title-13-bold">Supplementary Stage</div>
			<div class="title-13-normal mb-6">Claiming starts on the 12nd of July  at 7:00 (UTC+0) for a total of 2 weeks.</div>
			<div class="title-13-bold">Please note</div>
			<div class="title-13-normal">·Each eligible NFT will be converted into $UPTICK tokens according to the
				rules. You can view the $UPTICK rewards corresponding to each eligible NFT during the claiming stage.
			</div>
			<div class="title-13-normal">·At the end of the collection stage, a snapshot will be taken to generate a
				list of rewards, which means you need to collect before the period ends.</div>
			<div class="title-13-normal">·Winners need to claim their rewards weekly, for a total duration of 10 weeks.
				The claimable amount is the same. Those who fail to claim within the designated time period will be
				deemed to have automatically forfeited their rewards.</div>
			<!-- 缺少uptick地址暂时隐藏 -->
			<div class="title-13-normal">·After you've submitted your claim, please kindly wait patiently for the
				$UPTICK tokens to be transferred to you. To view them, simply switch the Keplr wallet to the Uptick
				node.</div>

			<div v-if="this.mobileAddress">
					<div class="title-13-bold mt-5" >Claim Wallet Address: {{this.mobileAddress ? this.mobileAddress :'—'}}</div>
					<div class="title-13-bold mt-1" >UPTICK Receiving Address: {{this.accountInfo.address ? this.accountInfo.address :'—'}}</div>
				</div>
				<div v-else>
					<div class="title-13-bold mt-5" >Claim Wallet Address: {{this.$store.state.did ? iaabaseinfo.address :'—'}}</div>
					<div class="title-13-bold mt-1" >UPTICK Receiving Address: {{this.$store.state.did ? accountInfo.address :'—'}}</div>
				</div>
			
			<!-- 预热活动标题 -->
			<!-- <div class="title-20" v-if="!isMobile">We are currently in the collection stage, which means you still have a chance to collect these NFTs!</div>
     <div class="mobile-title-20" v-else>We are currently in the collection stage, which means you still have a chance to collect these NFTs!</div> -->
		</div>

		<!-- 活动进入领取阶段 -->
		<div class="claim" v-if="this.$store.state.did || this.mobileAddress">
			<div class="Unclaimed">Your Total Unclaimed: <span>{{baseInfo.unclaimedAmount || 0}}</span> UPTICK</div>
			<div class="title-30">You can receive: <span>{{baseInfo.currentAmount || 0}}</span> UPTICK</div>
			<div class="title-13-notbold mt-4">This stage is a two-week supplementary campaign for $UPTICK airdrop, providing an opportunity for the users to reclaim the unclaimed Uptick Airdrop. If you did not claim your airdrops within the designated time window, you can claim 50% of the unclaimed UPTICK during the campaign period. 
			</div>
			<div class="Phase mt-6">Supplementary Phase</div>
			<div class="time mt-3">2023-07-12 07:00 - 2023-07-26 07:00 UTC+0</div>
			
			<div class="d-flex flex-lg-row  flex-column align-center">
				<button class="btn mt-6" v-if="baseInfo.status == 0 && baseInfo.currentAmount>0 "
					@click="claimPoint">Claim</button>
				<button class="notClaim mt-6" v-else>Claim</button>
				<div>
					<img src="@/assets/icons/icon2.png" width="27" height="27" alt="" class="ml-10 mt-6"
						@click="openRecords" v-if="installmentList != null">
					<img src="@/assets/icons/icon1.png" width="27" height="27" alt="" class="ml-6 mt-6"
						@click="openUptick()">
				</div>

			</div>

		</div>

		<div class="card" v-if="claimList.length > 0">
			<div class="claimlist">
				<div class=" titlerow ">
					<span class="item s1">NFT you own</span>
					<span class="item s2">Creator</span>
					<span class="item s3">Amount</span>
					<span class="item s4">Total Reward</span>


				</div>
				<div class="line mt-4"></div>

				<div class="claimItem mt-4" v-for="(item,index) in claimList" :key="index">
					<div class="item d-flex flex-row">
						<div class="s1 d-flex flex-row">
							<img :src="item.src" alt="">
							<div class="name ml-4">{{item.name}}</div>
						</div>
						<div class="s2 d-flex flex-row">
							<Avatar :size="42" :did="item.creatorAddress" imgType="middlev" :grade="item.creatorGrade"
								:hash="item.creatorProfilePhoto" />
							<div class="creatorName ml-4">{{item.creatorName}}</div>
						</div>
						<div class="s3">{{item.count}}</div>
						<div class="s4">{{item.amount}} UPTICK</div>

					</div>
					<div class="line mt-4"></div>

				</div>
			</div>
		</div>
		<div v-else class="card"> </div>

		<!-- 预热活动 -->
		<!-- <div class="cont d-flex flex-lg-row flex-wrap "  :class="{mobileCardList:isMobile}">
      <div
        class="indexfan d-flex flex-column"
        v-for="(item, index) in followList"
        :key="index"
        v-scroll="onScroll"
      >
        <div class="ava d-flex justify-center mt-6 mt-lg-6 mb-2 mb-lg-2">
          <Avatar
            style="margin: 0 auto"
            :size="70"
            :hash="item.profilePhoto"
            :grade="item.grade"
      imgType="bigv"
            showAvatarInfo
            :did="item.did"
          />
        </div>
        <div class="name">{{item.userName | namefilter}}</div>
      </div>
    </div> -->
		<loading :isShowLoading="isShowLoading"></loading>
		<div v-if="open" style="position: fixed; right: 20px; bottom: 20px; z-index: 99;">
			<message ref="message" @getOpen="Open" v-if="open" :currfriend="currfriend"></message>
		</div>
		<v-dialog v-model="openRecord">
			<InstallmentRecords v-if="openRecord" :installmentList="installmentList" @getOpen="Record">
			</InstallmentRecords>
		</v-dialog>
		<v-dialog v-model="openActive">
			<ActivityClaim v-if="openActive" :baseInfo="baseInfo" :irisAddress="this.mobileAddress" :upickAddress="this.accountInfo.address" projectType=3 @getOpen="Active"></ActivityClaim>
		</v-dialog>
		<v-dialog v-model="openAlert">
			<ActivityAlert v-if="openAlert" @getOpen="Alert"></ActivityAlert>
		</v-dialog>
	</div>
</template>

<script>
	import api from "@/api";
	import Loading from "@/components/loading.vue";
	import message from "@/components/popup/message.vue";
	import InstallmentRecords from "@/components/popup/installmentRecords.vue";
	import ActivityClaim from "@/components/popup/activityClaim.vue";
	import ActivityAlert from "@/components/popup/activityAlert.vue";
	import Avatar from "@/components/avatar/index.vue";
	import {
		getFileSrc
	} from "@/utils/file";
	import {
		getAddress
	} from "../../../src/keplr/wallet";
	import {
		addUptickNet
	} from "../../../src/uptick/wallet";
	const WORK_KEY = "WORK";
	export default {
		name: "follows",
		components: {
			message,
			Avatar,
			Loading,
			InstallmentRecords,
			ActivityClaim,
			ActivityAlert
		},
		data: () => ({
			isStatus: false,
			openRecord: false,
			openActive: false,
			openAlert: false,
			followList: [],
			pageNumber: 0,
			pageSize: 150,
			totalPage: false,
			isShowLoading: false,
			open: false,
			currfriend: {},
			claimList: [],
			installmentList: [],
			baseInfo: {},
			iaabaseinfo:{},
			accountInfo: {},
			mobileAddress:''
		}),
		computed: {
			isMobile: function() {
				return this.$vuetify.breakpoint.mobile;
			},
		},
		async created() {
			if (window.location.href.split('?')[1]) {
				this.mobileAddress = window.location.href.split('?')[1].split('=')[1];
				this.getUptickAddress();
			} else {
				addUptickNet();
				this.accountInfo = await getAddress(0);
				this.iaabaseinfo = await getAddress(1);
			}
			this.getClaimList();
			window.eventBus.$on('claimSuccess', this.claimSuccess);
		},
		filters: {
			namefilter: function(value) {
				if (value && value.length > 12) {
					return value.substr(0, 5) + "..." + value.substr(-5);
				} else {
					return value;
				}
			}
		},
		methods: {
			async getUptickAddress() {
				let params = {
					authenticationAddress: this.mobileAddress,
					did: this.mobileAddress
				}
				let uptickData = await api.home.getUptickAddress(params);
				if (uptickData && uptickData.success) {
					this.accountInfo.address = uptickData.data
					console.log(this.accountInfo)
				}
			},
			openRecords() {
				this.openRecord = true
			},
			openUptick() {

				//https://explorer.uptick.network/uptick-network-mainnet/account/uptick1km87h5lvgklw3hdecdx3nld6y8wrthaf4xl3j5
				//https://uptickscan.uptick.network/#/address/uptick1r82lpp2spxdp4vy9qcaa3w2xkcp5hh2ddwmu65
				window.open('https://explorer.uptick.network/uptick-network-mainnet/account/' + this.accountInfo.address, '_blank')

			},
			async claimPoint() {
				this.openActive = true


			},
			async getClaimList() {
				this.claimList = []
				let params = {}
				if (this.mobileAddress) {
					params = {
						projectType: 3,
						address: this.mobileAddress
					}
				} else {
					params = {
						projectType: 3,
						address: this.$store.state.did
					}
				}

				let claimListData = await api.home.claimList(params);
				this.baseInfo = claimListData.data
				let claimList = this.baseInfo.airdropActivityList
				this.installmentList = this.baseInfo.airdropLists
				if (claimList != null) {
					claimList.forEach(async (v) => {
						let imgSrc = await getFileSrc(WORK_KEY, v.imgUrl);
						this.claimList.push({
							src: imgSrc,
							name: v.content,
							amount: v.amount,
							count: v.count,
							creatorAddress: v.creatorAddress,
							creatorGrade: v.creatorGrade,
							creatorProfilePhoto: v.creatorProfilePhoto,
							creatorName: v.creatorName
						});
					});
				}


			},
			msgBtn(msg) {
				this.currfriend = {
					fansAddress: msg.did,
					fansName: msg.userName,
				};
				this.open = true;
				msg.wordsStatus = 1;
				this.$refs.message.init(this.currfriend);
				setTimeout(() => {
					window.eventBus.$emit("ViewMessage", true)
				}, 1000);
			},
			//弹框关闭后获取open值
			Open(e) {
				this.open = e;
				window.eventBus.$emit("ViewMessage", true);
			},
			async getFollow() {
				this.pageNumber++;
				let params = {
					pageSize: this.pageSize,
					pageNumber: this.pageNumber,
					sortType: 1,
					authenticationAddress: this.$store.state.did
				};
				this.isShowLoading = true;
				let res = await api.home.creatorVList(params);
				let list = res.data.list;
				this.followList = this.followList.concat(list);
				this.totalPage = res.data.totalPage;

				this.isShowLoading = false;
			},
			onScroll(e) {
				const {
					scrollTop,
					clientHeight,
					scrollHeight
				} =
				e.target.scrollingElement;

				if (
					scrollTop + clientHeight >= scrollHeight &&
					this.totalPage > this.pageNumber
				) {
					this.getFollow();
				}
			},
			Record(e) {
				this.openRecord = e
			},
			Active(e) {
				this.openActive = e
			},
			Alert(e) {
				this.openAlert = e
			},
			async claimSuccess() {
				console.log('claimSuccess');

				await this.getClaimList();
				this.openAlert = true
			}
		},
	};
</script>

<style lang="scss" scoped>
	img {
		cursor: pointer;
	}

	.contain {
		.contant {
			display: flex;
			flex-direction: column;
			justify-content: center;
			max-width: 850px;
			margin: 0 auto;

			.title-45 {
				font-family: Helvetica;
				font-size: 39px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 50px;
				letter-spacing: 0px;
				color: #270645;
				margin-top: 55px;
				text-align: center;
			}

			.mobile-title-20 {
				font-family: Helvetica;
				font-size: 20px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				letter-spacing: 0px;
				color: #270645;
				margin-top: 55px;
				text-align: center;
				margin: 60px auto 0;

			}

			.title-20 {
				font-family: Helvetica;
				font-size: 20px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				letter-spacing: 0px;
				color: #270645;
				margin-top: 55px;
				text-align: center;
				width: 600px;
				margin: 60px auto 0;
			}

			.des {
				font-family: Helvetica;
				font-size: 15px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #270645;
				word-wrap: break-word;
				text-align: left;
			}

			.title-13 {
				font-family: Helvetica;
				font-size: 13px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #270645;
			}

			.title-13-bold {
				font-family: Helvetica;
				font-size: 13px;
				font-weight: bold;
				letter-spacing: 0px;
				color: #270645;
			}

			.title-13-normal {
				font-family: Helvetica;
				font-size: 13px;
				font-weight: normal;
				letter-spacing: 0px;
				color: #766983;
				line-height: 20px;
			}

			.title-15 {

				font-family: Helvetica;
				font-size: 15px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 15px;
				letter-spacing: 0px;
				color: #270645;
			}

			.title-30 {
				font-family: Helvetica;
				font-size: 30px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 120px;
				letter-spacing: 1px;
				color: #270645;
			}
	

		}

		.card {
			max-width: 1220px;
			margin-bottom: 70px;

			.claimlist {
				width: 100%;
				margin-top: 90px;

				.line {
					width: 100%;
					height: 1px;
					border: solid 0.5px #cccccc;
				}

				.claimItem {
					.item {
						align-items: center;

						.s1 {
							width: 40%;
							align-items: center;

							img {
								width: 44px;
								height: 44px;
								object-fit: cover;
							}

							.name {
								width: 300px;
								font-family: Helvetica;
								font-size: 15px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 20px;
								letter-spacing: 0px;
								color: #270645;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}

						}

						.s2 {
							width: 20%;
							align-items: center;

							.creatorName {
								width: 200px;
								font-family: Helvetica;
								font-size: 15px;
								font-weight: normal;
								font-stretch: normal;
								line-height: 20px;
								letter-spacing: 0px;
								color: #270645;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}

						}

						.s3 {
							width: 30%;
							text-align: center;
						}

						.s4 {
							width: 10%;
						}

					}

				}

				.titlerow {
					width: 100%;
					display: flex;
					flex-direction: row;
					font-family: Helvetica;

					.item {
						font-size: 15px;
						font-weight: bold;
						font-stretch: normal;
						line-height: 20px;
						letter-spacing: 0px;
						color: #270645;
					}


					.s1 {
						width: 40%;

					}

					.s2 {
						width: 20%;
					}

					.s3 {
						width: 30%;
						text-align: center;


					}

					.s4 {
						width: 10%;

					}
				}

			}

			.cardlist {

				.itemIndex {
					margin-right: 25px;
					margin-top: 25px;

					&:nth-child(5n) {
						margin-right: 0;
					}

					.imgUrl {
						width: 221px;
						height: 221px;
						border-radius: 5px;
						object-fit: cover;
						cursor: pointer;
					}

					.nftName {
						width: 221px;
						font-family: Helvetica;
						font-size: 13px;
						font-weight: bold;
						font-stretch: normal;
						line-height: 20px;
						letter-spacing: 0px;
						color: #270645;
					}
				}


			}
		}


		.cont {
			max-width: 1220px;
			margin: 0 0 48px;

			.indexfan {
				cursor: pointer;
				width: 130px;
				height: 130px;
				background-image: linear-gradient(#ffffff, #ffffff),
					linear-gradient(#6f58d9, #6f58d9);
				background-blend-mode: normal, normal;
				box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
				border-radius: 5px;
				margin-right: 5px;
				margin-top: 20px;

				&:nth-child(9n) {
					margin-right: 0;
				}
			}

			.ava {
				width: 100%;
			}

			.num {
				font-family: Helvetica;
				font-size: 13px;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
				width: 100%;
				text-align: center;
			}

			.name {
				width: 100%;
				text-align: center;
				font-family: Helvetica;
				font-size: 15px;
				font-weight: bold;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
			}

		}

		.mobileCardList {
			max-width: 1220px;
			margin: 0 0 48px;
			justify-content: center;

			.indexfan {
				cursor: pointer;
				width: 130px;
				height: 130px;
				background-image: linear-gradient(#ffffff, #ffffff),
					linear-gradient(#6f58d9, #6f58d9);
				background-blend-mode: normal, normal;
				box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
				border-radius: 5px;
				margin-right: 5px;
				margin-top: 20px;

				&:nth-child(2n) {
					margin-right: 0;
				}
			}

			.ava {
				width: 100%;
			}

			.num {
				font-family: Helvetica;
				font-size: 13px;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
				width: 100%;
				text-align: center;
			}

			.name {
				width: 100%;
				text-align: center;
				font-family: Helvetica;
				font-size: 15px;
				font-weight: bold;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
			}

		}

		.claim {
			max-width: 850px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			.title-13-notbold {
				width: 608px;
				font-family: Helvetica;
				font-size: 13px;
				font-weight: normal;
				letter-spacing: 0px;
				color: #270645;
				text-align: center;

			}

			.title-30 {
				font-family: Helvetica;
				font-size: 30px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				color: #270645;
				margin-top: 10px;
				text-align: center;

				span {
					color: #c500ff;
				}
			}
			.Unclaimed{
				font-family: Helvetica;
				font-size: 30px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				color: #270645;
				margin-top: 55px;
				text-align: center;

				span {
					color: #c500ff;
				}
			}
			

			.Phase {
				font-family: Helvetica;
				font-size: 19px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 1px;
				color: #270645;

			}

			.time {
				font-family: Helvetica;
				font-size: 13px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #270645;
			}

			.receive {
				font-family: Helvetica;
				font-size: 19px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 1px;
				color: #270645;

				span {
					color: #c500ff;
				}
			}

			.btn {
				width: 276px;
				height: 51px;
				background-color: #270645;
				border-radius: 25px;
				font-family: Helvetica;
				font-size: 23px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				letter-spacing: 0px;
				color: #ffffff;
			}

			.notClaim {
				width: 276px;
				height: 51px;
				background-color: #766983;
				border-radius: 25px;
				font-family: Helvetica;
				font-size: 23px;
				font-weight: bold;
				font-stretch: normal;
				line-height: 30px;
				letter-spacing: 0px;
				color: #ffffff;
			}



		}

		&.mobile {
			.claim {
				.title-13-notbold {
					width: 300px;
				}

				.receive {
					text-align: center;
				}


			}

			.card {
				.claimlist {
					.titlerow {
						.s1 {
							width: 30%;
						}

						.s3 {
							width: 30%;
						}
					}
				}
			}

			.card {
				.claimlist {
					.claimItem {
						.item {
							.s1 {
								width: 30%;
							}

							.s3 {
								width: 30%;
							}
						}

					}
				}
			}


		}

	}
</style>
